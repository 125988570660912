import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { Globe, Upload, FileText, MapPin, Type, Package as PackageIcon, ExternalLink } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useClientStore } from '@/stores/useClientStore';
import { usePackageStore } from '@/stores/usePackageStore';
import { useSession } from 'next-auth/react';
import { PublicToggle } from './PublicToggle';
import { BannerText } from './BannerText';
import { TrainerDescription } from './TrainerDescription';
import { ServiceAreaZipcode } from './ServiceAreaZipcode';
import { PackageToggles } from './PackageToggles';
import { HeaderImageUpload } from './HeaderImageUpload';
import { SaveButton } from './SaveButton';
import { ToastNotification } from './ToastNotification';

interface SettingsSectionProps {
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode;
}

const SettingsSection = ({ icon, title, children }: SettingsSectionProps) => (
  <div className='group rounded-lg border border-transparent bg-white p-6 transition-colors hover:border-gray-200 hover:bg-gray-50'>
    <div className='mb-4 flex items-center gap-4'>
      <div className='flex h-14 w-14 items-center justify-center rounded-xl bg-blue-100 transition-colors group-hover:bg-blue-200'>
        {React.cloneElement(icon as React.ReactElement, { className: 'h-8 w-8 text-blue-600' })}
      </div>
      <h3 className='text-xl font-bold text-gray-900'>{title}</h3>
    </div>
    {children}
  </div>
);

function PublicPageSettings() {
  const { packages, fetchPackages, updatePackages } = usePackageStore();
  const [headerImage, setHeaderImage] = useState<string | null>(null);
  const [headerName, setHeaderName] = useState('');
  const [trainerDescription, setTrainerDescription] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const [localPackages, setLocalPackages] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [zipCode, setZipCode] = useState('');
  const { data: session } = useSession();
  const user = session?.user;

  useEffect(() => {
    if (user?.token) {
      fetchPackages(user.token);
      fetchTrainerSettings(user.token);
    }
  }, [user?.token, fetchPackages]);

  useEffect(() => {
    setLocalPackages(packages);
  }, [packages]);

  const fetchTrainerSettings = async (token: string) => {
    try {
      const response = await fetch(`https://api.otter-labs.com/api/publicPage/${user?.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setTrainerDescription(data.publicPage.trainerDescription || '');
      setIsPublic(data.publicPage.isPublic);
      setHeaderName(data.publicPage.headerName || '');
      setZipCode(data.publicPage.zipCodeServiceArea || '');
    } catch (error) {
      console.error('Error fetching trainer settings', error);
    }
  };

  const handleTrainerDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTrainerDescription(e.target.value);
  };

  const handlePackageToggle = (packageId: string) => {
    setLocalPackages((prevPackages) =>
      prevPackages.map((pkg) => (pkg.id === packageId ? { ...pkg, isPublic: !pkg.isPublic } : pkg)),
    );
  };

  const handlePublicToggle = () => {
    setIsPublic((prevIsPublic) => !prevIsPublic);
  };

  const handleZipCodeChange = (zip: string) => {
    setZipCode(zip);
  };

  const handleHeaderNameChange = (name: string) => {
    setHeaderName(name);
  };

  const handleSaveClick = async () => {
    setRequestInProgress(true);
    try {
      const response = await fetch(`https://api.otter-labs.com/api/publicPage/${user?.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify({
          headerImage,
          headerName,
          trainerDescription,
          packages: localPackages.filter((pkg) => pkg.type === 'enabled').map((pkg) => pkg.id),
          isPublic,
          zipCodeServiceArea: zipCode,
        }),
      });

      if (response.ok) {
        await updatePackages(localPackages, user?.token);
        await fetchPackages(user.token);
        setShowToast(true);
      }
    } catch (error) {
      console.error('Error updating public page', error);
    } finally {
      setRequestInProgress(false);
    }
  };

  const publicPageUrl = `https://barkontrack.com/${user?.id}`;

  return (
    <div className='relative isolate bg-white'>
      <div className='mx-auto max-w-7xl px-6 py-10 lg:px-8'>
        <div className='mb-12 flex items-center justify-between'>
          <div>
            <h1 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>Public Profile Settings</h1>
            <p className='mt-2 text-lg leading-8 text-gray-600'>
              Customize how your profile appears to potential clients
            </p>
          </div>
          <Link href={publicPageUrl} target='_blank' rel='noopener noreferrer'>
            <Button variant='outline' size='lg' className='inline-flex items-center gap-2 hover:bg-gray-50'>
              <ExternalLink className='h-5 w-5' />
              View Public Page
            </Button>
          </Link>
        </div>

        <div className='grid gap-8 md:grid-cols-2 lg:grid-cols-3'>
          <SettingsSection icon={<Globe />} title='Visibility'>
            <PublicToggle isPublic={isPublic} onToggle={handlePublicToggle} />
          </SettingsSection>

          <SettingsSection icon={<Type />} title='Banner Text'>
            <BannerText headerName={headerName} onHeaderNameChange={handleHeaderNameChange} />
          </SettingsSection>

          <SettingsSection icon={<Upload />} title='Header Image'>
            <HeaderImageUpload
              onImageUploadComplete={(res) => setHeaderImage(res[0].serverData.file)}
              onUploadError={(error) => alert(`ERROR! ${error.message}`)}
            />
          </SettingsSection>

          <SettingsSection icon={<MapPin />} title='Service Area'>
            <ServiceAreaZipcode zipCode={zipCode} onZipChange={handleZipCodeChange} />
          </SettingsSection>

          <SettingsSection icon={<FileText />} title='About You'>
            <TrainerDescription description={trainerDescription} onDescriptionChange={handleTrainerDescriptionChange} />
          </SettingsSection>

          <SettingsSection icon={<PackageIcon />} title='Available Packages'>
            <PackageToggles packages={localPackages} onToggle={handlePackageToggle} />
          </SettingsSection>
        </div>

        <div className='mt-12 flex justify-end border-t border-gray-200 pt-6'>
          <SaveButton onSave={handleSaveClick} isLoading={requestInProgress} />
        </div>
      </div>

      <ToastNotification isOpen={showToast} onOpenChange={setShowToast} />
    </div>
  );
}

export default PublicPageSettings;
