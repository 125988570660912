import { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import Image from 'next/image';

export function ContactSupportDialog() {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <div className='text-xsm cursor-pointer hover:underline'>Support</div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Contact Support</DialogTitle>
          <DialogDescription>
            If you need assistance or have any questions, please reach out to our support team.
          </DialogDescription>
        </DialogHeader>
        <div className='mt-4 flex items-center'>
          <Image src='/cara.jpeg' alt='Avatar' width={48} height={48} className='mr-4 h-12 w-12 rounded-full' />
          <p className='text-sm text-gray-500 dark:text-gray-400'>
            To contact support, please send an email to{' '}
            <a href='mailto:hello@barkontrack.com' className='text-blue-500 hover:underline'>
              hello@barkontrack.com
            </a>
            . Our support team will get back to you as soon as possible.
          </p>
        </div>
        <DialogFooter></DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
