import React from 'react';
import { Label } from '@/components/ui/label';
import { UploadButton } from '@/lib/uploadthing';

interface HeaderImageUploadProps {
  onImageUploadComplete: (res: any) => void;
  onUploadError: (error: Error) => void;
}

export const HeaderImageUpload: React.FC<HeaderImageUploadProps> = ({ onImageUploadComplete, onUploadError }) => {
  return (
    <div className='mt-6'>
      <Label htmlFor='headerImage' className='mb-2 block'>
        Header Image
      </Label>
      <UploadButton
        className='mt-4 ut-button:bg-black ut-button:ut-readying:bg-red-500/50'
        endpoint='imageUploader'
        onClientUploadComplete={onImageUploadComplete}
        onUploadError={onUploadError}
      />
    </div>
  );
};
