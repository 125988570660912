import React from 'react';

interface MessageContentProps {
  email: string;
  content: string;
}

export const MessageContent: React.FC<MessageContentProps> = ({ email, content }) => {
  return (
    <div>
      <p className='font-medium text-gray-400'>{email}</p>
      <p className='text-sm text-gray-500'>{content}</p>
    </div>
  );
};
