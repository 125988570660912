'use client';

import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '@/components/ui/dialog';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import PublicPageSettings from './PublicPageSettings/PublicPageSettings';

export function SettingsPanel({ open, onOpenChange }) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className='overflow-y-auto'>
        <DialogHeader>
          <DialogTitle>Settings Management</DialogTitle>
          <DialogDescription>Manage your account settings and preferences.</DialogDescription>
        </DialogHeader>
        <Tabs defaultValue='profile'>
          <div className='mt-2 flex items-center'>
            <TabsList>
              <TabsTrigger value='profile'>Public Page</TabsTrigger>
            </TabsList>
          </div>
          <TabsContent value='general'></TabsContent>
          <TabsContent value='profile'>
            <PublicPageSettings />
          </TabsContent>
          <TabsContent value='security'>
            <h2>Security Settings</h2>
            <p>Placeholder content for security settings.</p>
          </TabsContent>
          <TabsContent value='notifications'>
            <h2>Notification Settings</h2>
            <p>Placeholder content for notification settings.</p>
          </TabsContent>
        </Tabs>
        <DialogFooter />
      </DialogContent>
    </Dialog>
  );
}
