'use client';

import { useEffect, useState } from 'react';
import Link from 'next/link';
import { PawPrint, Home, MessageSquare, Settings, LogOut, Bug, Moon, Sun } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { MessagesDrawer } from '@/components/shared/usermenu/MessagesDrawer/MessagesDrawer';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { SettingsPanel } from './SettingsPanel/SettingsPanel';
import { useMessagesStore } from '@/stores/useMessagesStore';
import { useClearSession } from '@/hooks/useIsUserLoggedIn';
import { User } from '@/types/User';
import { useTheme } from 'next-themes';
import { cn } from '@/lib/utils';

interface MenuItemProps {
  href?: string;
  icon: React.ReactNode;
  label: React.ReactNode;
  badge?: number | null;
  onClick?: () => void;
  danger?: boolean;
  external?: boolean;
}

interface UserMenuProps {
  user: User;
}

export function UserMenu({ user }: UserMenuProps) {
  const { messages, fetchMessages } = useMessagesStore();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isMessagesOpen, setIsMessagesOpen] = useState(false);
  const [isDebugOpen, setIsDebugOpen] = useState(false);
  const clearSession = useClearSession();
  const { theme, setTheme } = useTheme();

  useEffect(() => {
    if (user) {
      fetchMessages(user.id, user.token);
    }
  }, []);

  const totalNotifications = messages.length;

  const MenuItem = ({ href, icon, label, badge, onClick, danger = false, external = false }: MenuItemProps) => {
    const content = (
      <>
        {icon}
        <span>{label}</span>
        {badge && (
          <Badge className='ml-auto' variant={danger ? 'destructive' : 'default'}>
            {badge}
          </Badge>
        )}
      </>
    );

    const className = cn(
      'flex w-full items-center rounded-md px-3 py-2 text-sm',
      danger
        ? 'text-destructive hover:bg-destructive/10'
        : 'text-foreground hover:bg-accent hover:text-accent-foreground',
    );

    if (href) {
      return (
        <Link href={href} className={className} {...(external ? { target: '_blank', rel: 'noopener noreferrer' } : {})}>
          {content}
        </Link>
      );
    }

    return (
      <button type='button' onClick={onClick} className={className}>
        {content}
      </button>
    );
  };

  const menuItems: MenuItemProps[] = [
    {
      href: '/dashboard',
      icon: <Home className='mr-2 h-4 w-4' />,
      label: 'Dashboard',
    },
    {
      href: `/${user.id}`,
      icon: <PawPrint className='mr-2 h-4 w-4' />,
      label: 'View Public Profile',
      external: true,
    },
    {
      onClick: () => setIsMessagesOpen(true),
      icon: <MessageSquare className='mr-2 h-4 w-4' />,
      label: 'Messages',
      badge: totalNotifications || null,
    },
    {
      href: '/settings',
      icon: <Settings className='mr-2 h-4 w-4' />,
      label: 'Settings',
    },
    {
      onClick: () => setTheme(theme === 'dark' ? 'light' : 'dark'),
      icon: theme === 'dark' ? <Sun className='mr-2 h-4 w-4' /> : <Moon className='mr-2 h-4 w-4' />,
      label: theme === 'dark' ? 'Light Mode' : 'Dark Mode',
    },
    {
      onClick: clearSession,
      icon: <LogOut className='mr-2 h-4 w-4' />,
      label: 'Logout',
      danger: true,
    },
  ];

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className={cn(
              'relative h-10 w-10 rounded-full',
              'border border-input bg-background',
              'shadow-sm transition-colors',
              'hover:bg-accent hover:text-accent-foreground',
              'focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
            )}
            size='icon'
            variant='outline'
          >
            {totalNotifications > 0 && (
              <Badge
                className='absolute right-0 top-0 -translate-y-1/2 translate-x-1/2 transform'
                variant='destructive'
              >
                {totalNotifications}
              </Badge>
            )}
            <PawPrint className='absolute inset-0 m-auto text-primary' size={24} />
            <span className='sr-only'>Toggle user menu</span>
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent align='end' className='w-56'>
          {menuItems.map((item, index) => (
            <div key={typeof item.label === 'string' ? item.label : index}>
              <DropdownMenuItem className='p-0 focus:bg-transparent'>
                <MenuItem {...item} />
              </DropdownMenuItem>
              {index < menuItems.length - 1 && <DropdownMenuSeparator />}
            </div>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>

      <MessagesDrawer messages={messages} open={isMessagesOpen} onOpenChange={setIsMessagesOpen} />
      <SettingsPanel open={isSettingsOpen} onOpenChange={setIsSettingsOpen} />
    </>
  );
}
