'use client';

import * as React from 'react';
import { Input } from '@/components/ui/input';
import { LoadingSpinner } from './loading-spinner';

const loadZipData = () => import('@/components/shared/uszips_surrounding_corrected.json');

type ZipCodeInputProps = {
  prefilledZip?: string;
  onZipChange?: (zip: string) => void;
  onSurroundingZipChange?: (selectedZips: string[]) => void;
};

export function ZipCodeInput({ prefilledZip = '', onZipChange, onSurroundingZipChange }: ZipCodeInputProps) {
  const [value, setValue] = React.useState(prefilledZip);
  const [selectedCity, setSelectedCity] = React.useState<string | null>(null);
  const [surroundingZips, setSurroundingZips] = React.useState<string[][] | null>(null);
  const [zipData, setZipData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);
  const [selectedSurroundingZips, setSelectedSurroundingZips] = React.useState<string[]>([]);

  React.useEffect(() => {
    loadZipData()
      .then((data) => {
        setZipData(data.default);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error loading ZIP data:', error);
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (prefilledZip && zipData) {
      const city = zipData[prefilledZip]?.city || null;
      const zips = zipData[prefilledZip]?.surrounding_zips || null;
      setSelectedCity(city);
      setSurroundingZips(zips);
      setSelectedSurroundingZips(zips ? zips.map(([zip]) => zip) : []);
    }
  }, [prefilledZip, zipData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;
    setValue(currentValue);

    if (onZipChange) {
      onZipChange(currentValue);
    }

    if (currentValue.length === 5 && zipData) {
      const city = zipData[currentValue]?.city || null;
      const zips = zipData[currentValue]?.surrounding_zips || null;
      setSelectedCity(city);
      setSurroundingZips(zips);
      setSelectedSurroundingZips(zips ? zips.map(([zip]) => zip) : []);
      setError(city ? null : 'Invalid');
    } else {
      setSelectedCity(null);
      setSurroundingZips(null);
      setSelectedSurroundingZips([]);
      setError(null);
    }
  };

  const handleToggle = (zip: string) => {
    let updatedSelection = [];
    if (selectedSurroundingZips.includes(zip)) {
      updatedSelection = selectedSurroundingZips.filter((item) => item !== zip);
    } else {
      updatedSelection = [...selectedSurroundingZips, zip];
    }
    setSelectedSurroundingZips(updatedSelection);

    if (onSurroundingZipChange) {
      onSurroundingZipChange(updatedSelection);
    }
  };

  return (
    <div className='mt-4'>
      <div className='flex items-center'>
        <Input
          id='zipInput'
          value={value}
          onChange={handleChange}
          maxLength={5}
          placeholder='Enter ZIP code'
          className='mr-2'
        />
        <div className='flex items-center'>
          {loading && (
            <div className='mt-2'>
              <LoadingSpinner />
            </div>
          )}
          {error && <div className='text-red-500'>{error}</div>}
          {selectedCity && (
            <div className='flex h-10 items-center rounded border border-gray-300 px-4'>
              {selectedCity.length > 8 ? `${selectedCity.slice(0, 6)}..` : selectedCity}
            </div>
          )}
        </div>
      </div>
      {surroundingZips && (
        <div className='mt-4'>
          <div className='flex flex-wrap'>
            {surroundingZips.map(([zip, city]) => (
              <div
                key={zip}
                className='my-1 flex w-full items-center justify-between rounded border border-gray-300 p-2'
              >
                <span>
                  {zip}: {city}
                </span>
                <input
                  type='checkbox'
                  className='h-5 w-5'
                  checked={selectedSurroundingZips.includes(zip)}
                  onChange={() => handleToggle(zip)}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
