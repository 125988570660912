import { create } from 'zustand';
import { Message } from '@/types/Message';

interface MessagesState {
  messages: Message[];
  isLoading: {
    fetch: boolean;
    archive: boolean;
  };
  error: string | null;
}

interface MessagesActions {
  fetchMessages: (userId: string, token: string) => Promise<void>;
  setMessages: (messages: Message[]) => void;
  archiveMessage: (id: string, userId: string, token: string) => Promise<void>;
  clearError: () => void;
}

type MessagesStore = MessagesState & MessagesActions;

const MESSAGES_API_URL = '/api/publicPage/messages';

export const useMessagesStore = create<MessagesStore>((set) => ({
  // Initial state
  messages: [],
  isLoading: {
    fetch: false,
    archive: false,
  },
  error: null,

  // Actions
  fetchMessages: async (userId: string, token: string) => {
    set((state) => ({
      ...state,
      isLoading: { ...state.isLoading, fetch: true },
      error: null,
    }));

    try {
      const response = await fetch(`${MESSAGES_API_URL}/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch messages: ${response.statusText}`);
      }

      const data: Message[] = await response.json();
      set((state) => ({
        ...state,
        messages: data,
        isLoading: { ...state.isLoading, fetch: false },
      }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to fetch messages';
      set((state) => ({
        ...state,
        error: errorMessage,
        isLoading: { ...state.isLoading, fetch: false },
      }));
      console.error('Error fetching messages:', error);
    }
  },

  setMessages: (messages) => {
    set((state) => ({ ...state, messages }));
  },

  archiveMessage: async (id: string, userId: string, token: string) => {
    set((state) => ({
      ...state,
      isLoading: { ...state.isLoading, archive: true },
      error: null,
    }));

    try {
      const response = await fetch(`${MESSAGES_API_URL}/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id, isArchived: true }),
      });

      if (!response.ok) {
        throw new Error(`Failed to archive message: ${response.statusText}`);
      }

      set((state) => ({
        ...state,
        messages: state.messages.map((msg) => (msg.id === id ? { ...msg, isArchived: true } : msg)),
        isLoading: { ...state.isLoading, archive: false },
      }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to archive message';
      set((state) => ({
        ...state,
        error: errorMessage,
        isLoading: { ...state.isLoading, archive: false },
      }));
      console.error('Error archiving message:', error);
    }
  },

  clearError: () => {
    set((state) => ({ ...state, error: null }));
  },
}));

const useMessages = () => useMessagesStore((state) => state.messages);
export const useMessagesLoading = () => useMessagesStore((state) => state.isLoading);
export const useMessagesError = () => useMessagesStore((state) => state.error);
