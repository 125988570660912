import React from 'react';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';

interface Package {
  id: string;
  name: string;
  isPublic: boolean;
}

interface PackageTogglesProps {
  packages: Package[];
  onToggle: (packageId: string) => void;
}

export const PackageToggles: React.FC<PackageTogglesProps> = ({ packages, onToggle }) => {
  return (
    <div>
      <Label className='mb-4 mt-2 mt-6 block'>Toggle Packages</Label>
      <div className='space-y-2'>
        {packages.map((pkg) => (
          <div key={pkg.id} className='flex items-center space-x-2'>
            <Switch id={`packageId${pkg.id}`} checked={pkg.isPublic} onCheckedChange={() => onToggle(pkg.id)} />
            <Label id={pkg.id} htmlFor={`packageId${pkg.id}`}>
              {pkg.name}
            </Label>
          </div>
        ))}
      </div>
    </div>
  );
};
