import React from 'react';
import { Toast, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from '@/components/ui/toast';

interface ToastNotificationProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

export const ToastNotification: React.FC<ToastNotificationProps> = ({ isOpen, onOpenChange }) => {
  return (
    <ToastProvider>
      <Toast open={isOpen} onOpenChange={onOpenChange}>
        <ToastTitle>Settings Saved</ToastTitle>
        <ToastDescription>Your public page settings have been successfully saved.</ToastDescription>
      </Toast>
      <ToastViewport />
    </ToastProvider>
  );
};
