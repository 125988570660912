// components/shared/Footer.tsx
'use client';
import { ContactSupportDialog } from './footer/ContactSupportDialog';
import { config } from '@/config';
import Link from 'next/link';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className='flex w-full flex-col items-center gap-2 border-t px-4 py-6 sm:flex-row'>
      <p className='text-xs text-gray-500 dark:text-gray-400'> {year} BarkOnTrack Inc. All rights reserved.</p>
      <nav className='flex gap-4 sm:ml-auto'>
        <Link href='/legal' className='text-xs hover:underline'>Terms & Privacy</Link>
        <div className='text-xs hover:underline'>
          <ContactSupportDialog />
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
