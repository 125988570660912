import React from 'react';
import { Label } from '@/components/ui/label';
import { ZipCodeInput } from '@/components/ui/ZipCodeInput';

interface ServiceAreaZipcodeProps {
  zipCode: string;
  onZipChange: (zip: string) => void;
}

export const ServiceAreaZipcode: React.FC<ServiceAreaZipcodeProps> = ({ zipCode, onZipChange }) => {
  return (
    <div>
      <Label htmlFor='zipCode' className='mb-4 mt-6 block'>
        Service Area Zipcode
      </Label>
      <ZipCodeInput prefilledZip={zipCode} onZipChange={onZipChange} />
    </div>
  );
};
