import React from 'react';
import { Button } from '@/components/ui/button';

interface SaveButtonProps {
  onSave: () => void;
  isLoading: boolean;
}

export const SaveButton: React.FC<SaveButtonProps> = ({ onSave, isLoading }) => {
  return (
    <div>
      <Button
        className='w-full flex-1 sm:w-auto'
        onClick={onSave}
        loading={isLoading}
        disabled={isLoading}
        type='submit'
      >
        Save
      </Button>
    </div>
  );
};
