import React from 'react';
import { Message } from '@/types/Message';
import { MessageAvatar } from './MessageAvatar';
import { MessageContent } from './MessageContent';
import { MessageActions } from './MessageActions';
import { cn } from '@/lib/utils';

interface MessageItemProps {
  message: Message;
  handleArchive: (id: string) => void;
  handleCreateClient: (email: string) => void;
}

export const MessageItem: React.FC<MessageItemProps> = ({ message, handleArchive, handleCreateClient }) => {
  return (
    <div
      className={cn(
        'hover:bg-muted/50 group flex items-start justify-between p-4 transition-colors',
        message.isNew && 'bg-muted/30',
      )}
    >
      <div className='flex flex-1 items-start gap-4'>
        <MessageAvatar email={message.email} avatar={message.avatar} />
        <MessageContent email={message.email} content={message.content} />
      </div>
      <div className='ml-4 opacity-0 transition-opacity group-hover:opacity-100'>
        <MessageActions
          messageId={message.id}
          email={message.email}
          isNew={message.isNew}
          handleArchive={handleArchive}
          handleCreateClient={handleCreateClient}
        />
      </div>
    </div>
  );
};
