import React from 'react';
import { Message } from '@/types/Message';
import { MessageItem } from './MessageItem';
import { InboxIcon } from 'lucide-react';

interface MessageListProps {
  messages: Message[];
  handleArchive: (id: string) => void;
  handleCreateClient: (email: string) => void;
}

export const MessageList: React.FC<MessageListProps> = ({ messages, handleArchive, handleCreateClient }) => {
  if (!messages || messages.length === 0) {
    return (
      <div className='flex h-32 flex-col items-center justify-center gap-2 p-4'>
        <InboxIcon className='text-muted-foreground h-8 w-8' />
        <p className='text-muted-foreground text-sm'>No messages to display</p>
      </div>
    );
  }

  return (
    <div className='divide-border divide-y'>
      {messages.map((message) => (
        <MessageItem
          key={message.id}
          message={message}
          handleArchive={handleArchive}
          handleCreateClient={handleCreateClient}
        />
      ))}
    </div>
  );
};
