import { Button } from '@/components/ui/button';
import { Archive } from 'lucide-react';
import React from 'react';

interface ArchiveAllButtonProps {
  handleArchiveAll: () => void;
  disabled: boolean;
  loading: boolean;
}

export const ArchiveAllButton: React.FC<ArchiveAllButtonProps> = ({ handleArchiveAll, disabled, loading }) => {
  return (
    <Button
      onClick={handleArchiveAll}
      disabled={disabled}
      variant='outline'
      size='sm'
      className='text-muted-foreground hover:text-foreground gap-2'
    >
      <Archive className='h-4 w-4' />
      {loading ? 'Archiving...' : 'Archive All'}
    </Button>
  );
};
