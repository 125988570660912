import React from 'react';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import { SmartTextarea } from '@/components/ui/smarttextarea';

interface TrainerDescriptionProps {
  description: string;
  onDescriptionChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TrainerDescription: React.FC<TrainerDescriptionProps> = ({ description, onDescriptionChange }) => {
  return (
    <div>
      <Label htmlFor='trainerDescription' className='mb-4 mt-6 block'>
        Trainer Description
      </Label>
      <Textarea
        id='trainerDescription'
        value={description}
        onChange={onDescriptionChange}
        placeholder='Enter trainer description'
        rows={4}
      />
    </div>
  );
};
