import React from 'react';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';

interface BannerTextProps {
  headerName: string | null;
  onHeaderNameChange: (name: string) => void;
}

export const BannerText: React.FC<BannerTextProps> = ({ headerName, onHeaderNameChange }) => {
  return (
    <div>
      <Label htmlFor='headerName' className='mb-4 mt-6 block'>
        Banner Text
      </Label>
      <Input
        id='headerName'
        value={headerName || ''}
        onChange={(e) => onHeaderNameChange(e.target.value)}
        placeholder='Your Banner Text'
      />
    </div>
  );
};
