import { create } from 'zustand';
import Cookies from 'js-cookie';

const useLoginStore = create((set) => ({
  email: '',
  password: '',
  showAlert: false,
  requestInProgress: false,
  open: false,
  isPremium: true,
  isProPlanSubscribed: false,
  isLoggedIn: false,
  setEmail: (email) => set({ email }),
  setPassword: (password) => set({ password }),
  setShowAlert: (showAlert) => set({ showAlert }),
  setRequestInProgress: (requestInProgress) => set({ requestInProgress }),
  setOpen: (open) => set({ open }),
  setIsLoggedIn: (isLoggedIn) => set({ isLoggedIn }),
  checkLoginStatus: () => {
    const token = Cookies.get('token');
    set({ isLoggedIn: Boolean(token) });
  },
  clearSession: () => {
    Cookies.remove('token');
    set({ isLoggedIn: false });
  },
}));

export default useLoginStore;
