import React from 'react';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';

interface MessageAvatarProps {
  email: string;
  avatar: string;
}

export const MessageAvatar: React.FC<MessageAvatarProps> = ({ email, avatar }) => {
  return (
    <Avatar className='h-10 w-10'>
      <AvatarImage alt={email} src={avatar} />
      <AvatarFallback>{email.charAt(0)}</AvatarFallback>
    </Avatar>
  );
};
