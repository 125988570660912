import React from 'react';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@/components/ui/drawer';
import { useMessagesStore, useMessagesLoading, useMessagesError } from '@/stores/useMessagesStore';
import { useClientStore } from '@/stores/useClientStore';
import { useSession } from 'next-auth/react';
import { useToast } from '@/components/ui/use-toast';
import { Message } from '@/types/Message';
import { MessageList } from './MessageList';
import { ArchiveAllButton } from './ArchiveAllButton';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Loader2, MailIcon } from 'lucide-react';
import { useMessages } from '@/hooks/queries/useMessages';
import { useQueryClient } from '@tanstack/react-query';

interface MessagesDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  messages: Message[];
}

export function MessagesDrawer({ open, onOpenChange, messages }: MessagesDrawerProps) {
  const archiveMessage = useMessagesStore((state) => state.archiveMessage);
  const { fetchMessages, clearError } = useMessagesStore();
  const { data: session } = useSession();
  const user = session?.user;
  const { data: fetchedMessages, isLoading, error } = useMessages(user?.token);
  const { createClient, fetchClients } = useClientStore();
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const handleArchive = async (id: string) => {
    if (user) {
      await archiveMessage(id, user.id, user.token);
      queryClient.invalidateQueries({ queryKey: ['messages'], meta: { token: user.token } });
    }
  };

  const handleArchiveAll = async () => {
    if (user) {
      for (const message of messages || []) {
        await archiveMessage(message.id, user.id, user.token);
      }
      queryClient.invalidateQueries({ queryKey: ['messages'], meta: { token: user.token } });
      toast({
        title: 'Success',
        description: 'All messages archived',
      });
    }
  };

  const handleCreateClient = async (email: string) => {
    if (user) {
      try {
        await createClient('New Client', email, '123-456-7890', 'Labrador', user.id, user.token);
        await fetchClients(user.token);
        toast({
          title: 'Success',
          description: 'Client created successfully',
        });
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Failed to create client',
          variant: 'destructive',
        });
      }
    }
  };

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent className='h-[85vh]'>
        <DrawerHeader className='border-border border-b px-6 py-4'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-2'>
              <MailIcon className='h-5 w-5 text-yellow-600' />
              <div>
                <DrawerTitle>Messages</DrawerTitle>
                <DrawerDescription>
                  {messages?.length} message{messages?.length !== 1 ? 's' : ''}
                </DrawerDescription>
              </div>
            </div>
            {messages?.length > 0 && (
              <ArchiveAllButton handleArchiveAll={handleArchiveAll} disabled={isLoading} loading={isLoading} />
            )}
          </div>
        </DrawerHeader>

        <div className='flex-1 overflow-y-auto'>
          {error && (
            <Alert variant='destructive' className='m-4'>
              <AlertDescription>{error instanceof Error ? error.message : 'An error occurred'}</AlertDescription>
            </Alert>
          )}

          {isLoading ? (
            <div className='flex h-32 items-center justify-center'>
              <Loader2 className='text-muted-foreground h-6 w-6 animate-spin' />
            </div>
          ) : messages?.length === 0 ? (
            <div className='flex h-32 items-center justify-center'>
              <p className='text-muted-foreground text-sm'>No messages yet</p>
            </div>
          ) : (
            <MessageList messages={messages} handleArchive={handleArchive} handleCreateClient={handleCreateClient} />
          )}
        </div>

        <DrawerFooter className='border-border border-t px-6 py-4'>
          <p className='text-muted-foreground text-center text-sm'>
            {messages?.length} message{messages?.length !== 1 ? 's' : ''} total
          </p>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
