import React from 'react';
import { Badge } from '@/components/ui/badge';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu';
import { MoreHorizontal } from 'lucide-react';

interface MessageActionsProps {
  messageId: string;
  email: string;
  isNew: boolean;
  handleArchive: (id: string) => void;
  handleCreateClient: (email: string) => void;
}

export const MessageActions: React.FC<MessageActionsProps> = ({
  messageId,
  email,
  isNew,
  handleArchive,
  handleCreateClient,
}) => {
  return (
    <div className='flex items-center gap-2'>
      {isNew && <Badge variant='secondary'>New</Badge>}
      <DropdownMenu>
        <DropdownMenuTrigger>
          <MoreHorizontal
            className='h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-700'
            aria-label='More options'
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={() => handleCreateClient(email)}>Create as Client</DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleArchive(messageId)}>Archive</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
