import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Message } from '@/types/Message';

export function useMessages(token: string | undefined) {
  return useQuery({
    queryKey: ['messages', token],
    queryFn: async () => {
      if (!token) return [];
      const response = await axios.get('/api/messages', {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data as Message[];
    },
    enabled: !!token,
  });
}
