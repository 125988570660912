import React from 'react';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';
import { Globe, Lock } from 'lucide-react';

interface PublicToggleProps {
  isPublic: boolean;
  onToggle: () => void;
}

export const PublicToggle: React.FC<PublicToggleProps> = ({ isPublic, onToggle }) => {
  return (
    <div className='space-y-4'>
      <div className='flex items-start justify-between rounded-lg border border-gray-200 p-4'>
        <div className='space-y-1'>
          <div className='flex items-center gap-2'>
            {isPublic ? <Globe className='h-5 w-5 text-blue-600' /> : <Lock className='h-5 w-5 text-gray-500' />}
            <Label htmlFor='isPublic' className='text-base font-semibold text-gray-900'>
              {isPublic ? 'Public Profile' : 'Private Profile'}
            </Label>
          </div>
          <p className='text-sm text-gray-500'>
            {isPublic
              ? 'Your profile is visible to potential clients'
              : 'Your profile is currently hidden from public view'}
          </p>
        </div>
        <Switch
          id='isPublic'
          checked={isPublic}
          onCheckedChange={onToggle}
          className='data-[state=checked]:bg-blue-600'
        />
      </div>
      {!isPublic && (
        <div className='rounded-md bg-yellow-50 p-4'>
          <div className='flex items-center gap-2 text-sm text-yellow-800'>
            <Lock className='h-4 w-4' />
            <p>When private, clients cannot view your profile or packages. Make public to start receiving inquiries.</p>
          </div>
        </div>
      )}
    </div>
  );
};
